import React from "react";
import styled from "styled-components";
import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "components/headers/light.js";
import tw from "twin.macro";
import { useNavigate } from "react-router-dom";

const Navbar = ({ color = "light" }) => {
  const StyledHeader = styled(Header)`
    ${tw`pt-8 max-w-none w-full`}

    ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
      ${({ color }) =>
        color === "dark"
          ? tw`text-black hover:border-black hover:text-black`
          : tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
      ${tw`cursor-pointer`}
    }

    ${NavToggle}.closed {
      ${tw`text-black hover:text-primary-500`}
    }
  `;
  const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;

  const router = useNavigate();
  const navLinks = [
    <NavLinks key={1}>
      <NavLink className="cursor-pointer" onClick={() => router("/")}>
        Home
      </NavLink>
      <NavLink onClick={() => router("/about-us")}>About Us</NavLink>
      <NavLink onClick={() => router("/investment-consulting")}>
        Investment Consulting
      </NavLink>
      <NavLink onClick={() => router("/rpa-service")}>RPA Service</NavLink>
      <NavLink onClick={() => router("/auditing-consulting")}>
        Auditing Consulting
      </NavLink>
      <NavLink onClick={() => router("/educational-service")}>
        Educational Service
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink
        style={{ color: "white" }}
        onClick={() => router("/contact-us")}
      >
        Contact Us
      </PrimaryLink>
    </NavLinks>,
  ];
  return <StyledHeader color={color} links={navLinks} />;
};

export default Navbar;
