import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { DesktopNavLinks, LogoLink, NavLink, NavLinks, NavToggle, PrimaryLink } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";
import { useNavigate } from "react-router-dom";
import Navbar from "./new/common/Navbar";
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-black`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  const router = useNavigate();

  const navLinks = [
    <NavLinks key={1}>
      <NavLink onClick={() => router("/")}>Home</NavLink>
      <NavLink onClick={() => router("/about-us")}>About Us</NavLink>
      <NavLink onClick={() => router("/investment-consulting")}>
        Investment Consulting
      </NavLink>
      <NavLink onClick={() => router("/rpa-service")}>RPA Service</NavLink>
      <NavLink onClick={() => router("/auditing-consulting")}>
        Auditing Consulting
      </NavLink>
      <NavLink onClick={() => router("/educational-service")}>
        Educational Service
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink onClick={() => router("contact-us")}>Contact Us</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <AnimationRevealPage>
      <Navbar color="dark" />
      <MainFeature1
        subheading={<Subheading>About Aikyam Insight</Subheading>}
        heading="We are your trusted partner in business consulting."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        description="At Aikyam Insight, we believe in unlocking your business's full potential through our professional consulting services. Our expert guidance and tailored solutions are designed to help businesses thrive in today’s competitive landscape. With a dedicated team of experienced consultants, we navigate the complexities of the business world to drive sustainable growth."
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="We aim to empower businesses through expert consulting."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
        description="Our vision is to transform the way businesses operate by providing insightful strategies and innovative solutions. We strive to be at the forefront of business consulting, continuously adapting to meet the needs of our clients while helping them achieve their goals."
      />
      <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We uphold these principles."
        description="At Aikyam Insight, our core values guide everything we do:
        Integrity, Collaboration and Excellence."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description:
              "Our dedicated support team is available 24/7 to assist you with any questions or issues.",
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description:
              "At Aikyam Insight, we pride ourselves on having a diverse and skilled team of professionals.",
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description:
              "We are dedicated to ensuring our clients are fully satisfied with our services.",
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
