import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Footer from "components/footers/FiveColumnWithBackground.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import Navbar from "./new/common/Navbar";

const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Navbar color="dark" />
      <ContactUsForm
        description="At Aikyam Insight, we value open communication and are always ready to assist you with any inquiries or support you may need. Whether you have questions about our services, want to discuss a project, or need advice on how to elevate your business, our team is just a message away. Your success is our priority, and we look forward to collaborating with you.
Feel free to reach out to us at service@aikyaminsight.com and +91 - 7721987334, or fill out the form below, and we’ll get back to you as soon as possible."
      />
      <ContactDetails
        cards={[
          {
            title: "Pune",
            description: (
              <>
                <Email>contact@aikyaminsight.com</Email>
                <Phone>+91 - 7721987334</Phone>
              </>
            ),
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
