import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

// import Hero from "components/hero/TwoColumnWithVideo.js";
// import Hero from "components/hero/TwoColumnWithInput.js";
// import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
// import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
// import Hero from "components/hero/FullWidthWithImage.js";
// import Hero from "components/hero/BackgroundAsImage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
// import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import customerSupportIllustrationSrc from "images/customer-support-illustration.svg";
import Pricing from "components/pricing/ThreePlans.js";
import { Subheading } from "components/misc/Headings";

const HomePage = () => {
  const HighlightedText = tw.span`text-primary-500`;
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      <Pricing
        description="We offer a range of pricing options designed to fit your budget and requirements. Our plans are crafted to ensure you receive the best value for the services you need, with no hidden costs or surprises."
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "₹1000",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [
            ],
          },
          {
            name: "Business",
            price: "₹2000",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: [
            ],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "₹3000",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
            ],
          },
        ]}
      />
      <MainFeature
        description={
          "We have been committed to delivering exceptional results and empowering businesses to thrive. Our experience, expertise, and dedication have driven us to consistently meet the evolving needs of our clients, ensuring their success in today’s competitive market."
        }
      />
      <ContactUsForm
        description="We’re Here to Help — Reach Out Anytime.
Have questions or need assistance? Our team is always ready to support you. Feel free to contact us, and we’ll get back to you promptly with the answers and solutions you need."
      />
      <Footer />
    </AnimationRevealPage>
  );
};

export default HomePage;
