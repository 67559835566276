import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Footer from "components/footers/FiveColumnWithBackground.js";

const HighlightedText = tw.span`text-primary-500`

const EducationalServices = () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <FeatureStats/>
      <Features 
        heading={<>Our <HighlightedText>Services</HighlightedText></>}
      />
      <MainFeature
      description="At Aikyam Insight, we provide comprehensive educational services, including career counseling, mentoring, and institutional support. Our goal is to help individuals unlock their potential, align their passions with career aspirations, and grow professionally with structured guidance."
        heading={<>Empowering Careers with <HighlightedText>Expert Guidance</HighlightedText></>}
      />
      <Footer />
    </AnimationRevealPage>
  )
}

export default EducationalServices