import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryLink as PrimaryLinkBase } from "components/misc/Links.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as TimeIcon } from "feather-icons/dist/icons/clock.svg";
import { ReactComponent as TrendingIcon } from "feather-icons/dist/icons/trending-up.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const ThreeColumn = tw.div`flex flex-wrap`;
const Column = tw.div``;
const HeadingColumn = tw(Column)`w-full xl:w-1/3`;
const CardColumn = tw(Column)`w-full md:w-1/2 xl:w-1/3 mt-16 xl:mt-0`;

const HeadingInfoContainer = tw.div`text-center xl:text-left max-w-lg xl:max-w-none mx-auto xl:mx-0`;
const HeadingTitle = tw(SectionHeading)`xl:text-left leading-tight`;
const HeadingDescription = tw.p`text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-8`;
const PrimaryLink = styled(PrimaryLinkBase)`
  ${tw`inline-flex justify-center xl:justify-start items-center mt-8 text-lg`}
  svg {
    ${tw`ml-2 w-5 h-5`}
  }
`;

const Card = tw.div`mx-auto xl:mx-0 xl:ml-auto max-w-sm md:max-w-xs lg:max-w-sm xl:max-w-xs`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-80 bg-cover bg-center rounded`
]);

const CardText = tw.div`mt-4`;

const CardHeader = tw.div`flex justify-between items-center`;
const CardType = tw.div`text-primary-500 font-bold text-lg`;
const CardPrice = tw.div`font-semibold text-sm text-gray-600`;
const CardPriceAmount = tw.span`font-bold text-gray-800 text-lg`;

const CardTitle = tw.h5`text-xl mt-4 font-bold`;

const CardMeta = styled.div`
  ${tw`flex flex-row flex-wrap justify-between sm:items-center font-semibold tracking-wide text-gray-600 uppercase text-xs`}
`;

const CardMetaFeature = styled.div`
  ${tw`flex items-center mt-4`}
  svg {
    ${tw`w-5 h-5 mr-1`}
  }
`;
const CardAction = tw(PrimaryButtonBase)`w-full mt-8`;

export default () => {
  const oldcards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1553194587-b010d08c6c56?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      type: "Beachfront",
      pricePerDay: "$99",
      title: "A Trip to the Bahamas and the Carribean Ocean",
      trendingText: "Trending",
      durationText: "7 Days Tour",
      locationText: "Africa"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1584200186925-87fa8f93be9b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80",
      type: "Cruise",
      pricePerDay: "$169",
      title: "Cruise to the Mariana Trench and the Phillipines",
      trendingText: "Trending",
      durationText: "15 Days Tour",
      locationText: "Australia"
    }
  ];
  const cards = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1586880244404-4f7e96cc5f6e?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
      type: "Finance and Banking",
      title: "Automating Financial Workflows",
      description: "Streamline processes like loan processing, fraud detection, and compliance reporting.",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1559028012-481c0d59da68?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
      type: "Healthcare",
      title: "Enhancing Patient Management",
      description: "Automate patient scheduling, billing, and medical record management for better care.",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1509005087049-92c5ee8a6ddf?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
      type: "Insurance",
      title: "Claims Processing Automation",
      description: "Improve claims accuracy and speed with automated document handling and underwriting.",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1581091870628-a6c06a180851?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
      type: "Manufacturing",
      title: "Production Line Automation",
      description: "Optimize production with automated quality checks and supply chain tracking.",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1599058917720-7868c2f4900a?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
      type: "Logistics and Supply Chain",
      title: "Automating Logistics Operations",
      description: "Enhance efficiency with automated inventory management and shipment tracking.",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1578390435329-7c2577e43568?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
      type: "Government",
      title: "Process Automation in Public Services",
      description: "Automate citizen services, document management, and compliance workflows.",
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1596422734467-640fe5bcaa98?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80",
      type: "Retail",
      title: "Improving Retail Operations",
      description: "Automate inventory replenishment, customer service, and loyalty program management.",
    },
  ];
  
  return (
    <Container>
      <Content>
        <ThreeColumn>
          <HeadingColumn>
            <HeadingInfoContainer>
              <HeadingTitle>Explore Industry-Specific RPA Solutions</HeadingTitle>
              <HeadingDescription>
              Discover how automation transforms industries—boosting efficiency, reducing costs, and driving innovation across Finance, Healthcare, Retail, and more.
              </HeadingDescription>
            </HeadingInfoContainer>
          </HeadingColumn>
          {cards.map((card, index) => (
            <CardColumn key={index}>
              <Card>
                {/* <CardImage imageSrc={card.imageSrc} /> */}
                <CardText>
                  <CardHeader>
                    <CardType>{card.type}</CardType>
                    <CardPrice>
                      <CardPriceAmount>{card.pricePerDay}</CardPriceAmount>
                    </CardPrice>
                  </CardHeader>
                  <CardTitle>{card.title}</CardTitle>
                </CardText>
              </Card>
            </CardColumn>
          ))}
        </ThreeColumn>
      </Content>
    </Container>
  );
};
