import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Services from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import FAQ from "components/faqs/TwoColumnPrimaryBackground.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg";
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg";
import Features from "components/features/DashedBorderSixFeatures";

const AuditingAndAccountingConsulting = () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features
        cards={[
          {
            title: "Financial Statement Audits",
            route: "/financial-statement-audits",
            description:
              "Ensure the accuracy of your financial records with our thorough financial statement audits, designed to meet regulatory standards and provide valuable insights.",
          },
          {
            title: "Internal Control Assessments",
            route: "/internal-control-assessments",
            description:
              "Strengthen your organization’s internal controls with our assessments, helping you identify weaknesses and enhance operational efficiency.",
          },
          {
            title: "Compliance Audits (GAAP, IFRS, SOX)",
            route: "/compliance-audits",
            description:
              "Stay compliant with industry regulations through our comprehensive audits, ensuring adherence to GAAP, IFRS, and SOX standards.",
          },
          {
            title: "Financial Planning & Budgeting",
            route: "/financial-planning",
            description:
              "Achieve your financial goals with expert financial planning and budgeting services tailored to your unique needs and objectives.",
          },
          {
            title: "Tax Planning & Preparation",
            route: "/tax-planning",
            description:
              "Navigate the complexities of tax regulations with our professional tax planning and preparation services, designed to optimize your tax position.",
          },
          {
            title: "Risk Management & Internal Audit",
            route: "/risk-management",
            description:
              "Minimize risks and enhance your organization's resilience with our risk management and internal audit services, ensuring strategic oversight.",
          },
        ]}
      />
      <Services />
      <MainFeature
        subheading="Compliant"
        heading="Ensuring Regulatory Compliance"
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false} 
        description="Our auditing services help you navigate complex regulatory landscapes, ensuring adherence to GAAP, IFRS, and SOX standards for peace of mind."
  
      />
      <MainFeature
        subheading="Accurate"
        heading="Delivering Financial Accuracy"
        imageSrc={serverSecureIllustrationImageSrc}
        description="With meticulous financial statement audits and internal control assessments, we enhance your organization’s financial accuracy and transparency."
        buttonRounded={false}
        textOnLeft={false}
      />
      <Footer />
    </AnimationRevealPage>
  );
};

export default AuditingAndAccountingConsulting;
