import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Blog from "components/blogs/PopularAndRecentBlogPosts.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import SubscribeNewsLetterForm from "components/forms/SimpleSubscribeNewsletter.js";
import Footer from "components/footers/FiveColumnWithBackground.js";

const RobotProcessAutomationService = () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <SliderCard />
      <Features
        cards={[
          {
            imageSrc: "https://example.com/process-assessment.png",
            title: "Process Assessment and Automation",
            route: "/process-assessment",
            description:
              "Evaluate and automate your business processes to increase efficiency, reduce costs, and eliminate bottlenecks.",
          },
          {
            imageSrc: "https://example.com/workflow-automation.png",
            title: "Workflow Automation",
            route: "/workflow-automation",
            description:
              "Streamline and optimize workflows across departments, ensuring faster turnaround times and improved collaboration.",
          },
          {
            imageSrc: "https://example.com/content-management.png",
            title: "Content Creation and Management",
            route: "/content-management",
            description:
              "Automate repetitive content tasks, ensuring seamless creation, organization, and management of digital assets.",
          },
          {
            imageSrc: "https://example.com/website-task-automation.png",
            title: "Website Task Automation",
            route: "/website-automation",
            description:
              "Automate routine website operations, from data scraping to content updates, for faster, error-free performance.",
          },
          {
            imageSrc: "https://example.com/data-entry.png",
            title: "Data Entry and Processing",
            route: "/data-entry",
            description:
              "Reduce manual efforts with automated data entry and processing, ensuring speed and accuracy in data handling.",
          },
          {
            imageSrc: "https://example.com/document-management.png",
            title: "Document Management",
            route: "/document-management",
            description:
              "Automate the organization and retrieval of documents to improve document workflows and enhance accessibility.",
          },
          {
            imageSrc: "https://example.com/customer-service.png",
            title: "Customer Service Automation",
            route: "/customer-service",
            description:
              "Enhance customer experience with automated responses, ticketing systems, and support workflows.",
          },
          {
            imageSrc: "https://example.com/accounts-payable.png",
            title: "Accounts Payable and Receivable Automation",
            route: "/accounts-automation",
            description:
              "Automate invoice processing, payments, and collections to ensure seamless financial operations.",
          },
          {
            imageSrc: "https://example.com/hr-automation.png",
            title: "HR Process Automation",
            route: "/hr-automation",
            description:
              "Simplify recruitment, onboarding, and employee management with automated HR processes.",
          },
          {
            imageSrc: "https://example.com/custom-rpa.png",
            title: "Custom RPA Solutions",
            route: "/custom-rpa",
            description:
              "Build tailored RPA solutions that align with your unique business requirements and goals.",
          },
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};

export default RobotProcessAutomationService;
